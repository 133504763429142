import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Heading, H1, H2, H3, Text } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "heading"
    }}>{`Heading`}</h1>
    <Props of={Heading} mdxType="Props" />
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={1} __code={'<H1>Heading 1</H1>\n<Text as=\"p\">\n  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque\n  hendrerit arcu rhoncus, feugiat metus ac, ultricies tortor. Lorem ipsum\n  dolor sit amet, consectetur adipiscing elit. Phasellus libero arcu, laoreet\n  ac leo at, aliquam lacinia augue. Suspendisse tincidunt metus tortor, et\n  mollis ipsum elementum vel.\n</Text>\n<H2>Heading 2</H2>\n<Text as=\"p\">\n  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque\n  hendrerit arcu rhoncus, feugiat metus ac, ultricies tortor. Lorem ipsum\n  dolor sit amet, consectetur adipiscing elit.\n</Text>\n<Text as=\"p\">\n  Phasellus libero arcu, laoreet ac leo at, aliquam lacinia augue. Suspendisse\n  tincidunt metus tortor, et mollis ipsum elementum vel.\n</Text>\n<H3>Heading 3</H3>\n<Text as=\"p\">\n  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque\n  hendrerit arcu rhoncus, feugiat metus ac, ultricies tortor. Lorem ipsum\n  dolor sit amet, consectetur adipiscing elit. Phasellus libero arcu, laoreet\n  ac leo at, aliquam lacinia augue. Suspendisse tincidunt metus tortor, et\n  mollis ipsum elementum vel.\n</Text>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Heading,
      H1,
      H2,
      H3,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <H1 mdxType="H1">Heading 1</H1>
  <Text as="p" mdxType="Text">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
    hendrerit arcu rhoncus, feugiat metus ac, ultricies tortor. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit. Phasellus libero arcu, laoreet
    ac leo at, aliquam lacinia augue. Suspendisse tincidunt metus tortor, et
    mollis ipsum elementum vel.
  </Text>
  <H2 mdxType="H2">Heading 2</H2>
  <Text as="p" mdxType="Text">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
    hendrerit arcu rhoncus, feugiat metus ac, ultricies tortor. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit.
  </Text>
  <Text as="p" mdxType="Text">
    Phasellus libero arcu, laoreet ac leo at, aliquam lacinia augue. Suspendisse
    tincidunt metus tortor, et mollis ipsum elementum vel.
  </Text>
  <H3 mdxType="H3">Heading 3</H3>
  <Text as="p" mdxType="Text">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
    hendrerit arcu rhoncus, feugiat metus ac, ultricies tortor. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit. Phasellus libero arcu, laoreet
    ac leo at, aliquam lacinia augue. Suspendisse tincidunt metus tortor, et
    mollis ipsum elementum vel.
  </Text>
    </Playground>
    <h2 {...{
      "id": "display-size"
    }}>{`Display size`}</h2>
    <Playground __position={2} __code={'<H1 displaySize={1}>Display size 1</H1>\n<H2 displaySize={2}>Display size 2</H2>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Heading,
      H1,
      H2,
      H3,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <H1 displaySize={1} mdxType="H1">Display size 1</H1>
  <H2 displaySize={2} mdxType="H2">Display size 2</H2>
    </Playground>
    <h2 {...{
      "id": "typo-styles"
    }}>{`Typo styles`}</h2>
    <Playground __position={3} __code={'<H1 typoStyle=\"xxl\">XXL typo style</H1>\n<H2 typoStyle=\"sm\">SM typo style</H2>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Heading,
      H1,
      H2,
      H3,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <H1 typoStyle="xxl" mdxType="H1">XXL typo style</H1>
  <H2 typoStyle="sm" mdxType="H2">SM typo style</H2>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      